/**
 * This file is being used by injecting custom TailwindCSS variants.
 *
 * These variants are different because these will not generate any
 * CSS rules, but they will generate SCSS variables from your Tailwind
 * config file.
 *
 * The generated output will be used by Beagle.
 * Do NOT modify or use this file to generate your own variants.
 */

$beagle-elevations: (
  'xs': '0 0 0 1px rgba(0, 0, 0, 0.05)',
 'sm': '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
 'default': '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
 'md': '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
 'lg': '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
 'xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
 '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
 'inner': 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
 'outline': '0 0 0 3px rgba(66, 153, 225, 0.5)',
 'none': 'none',
 'solid': '0 0 0 2px currentColor',
 ) !default;

$beagle-colors: (
  'white': (
  50: #FFFFFF,
 100: #FFFFFF,
 200: #FFFFFF,
 300: #FFFFFF,
 400: #FFFFFF,
 500: #FFFFFF,
 600: #FFFFFF,
 700: #FFFFFF,
 800: #FFFFFF,
 900: #FFFFFF,
 contrast: (
  50: #252F3F,
 100: #252F3F,
 200: #252F3F,
 300: #252F3F,
 400: #252F3F,
 500: #252F3F,
 600: #252F3F,
 700: #252F3F,
 800: #252F3F,
 900: #252F3F,
 )
),
 'black': (
  50: #000000,
 100: #000000,
 200: #000000,
 300: #000000,
 400: #000000,
 500: #000000,
 600: #000000,
 700: #000000,
 800: #000000,
 900: #000000,
 contrast: (
  50: #FFFFFF,
 100: #FFFFFF,
 200: #FFFFFF,
 300: #FFFFFF,
 400: #FFFFFF,
 500: #FFFFFF,
 600: #FFFFFF,
 700: #FFFFFF,
 800: #FFFFFF,
 900: #FFFFFF,
 )
),
 'gray': (
  50: #F9FAFB,
 100: #F4F5F7,
 200: #E5E7EB,
 300: #D2D6DC,
 400: #9FA6B2,
 500: #6B7280,
 600: #4B5563,
 700: #374151,
 800: #252F3F,
 900: #161E2E,
 contrast: (
  50: #161E2E,
 100: #161E2E,
 200: #161E2E,
 300: #161E2E,
 400: #161E2E,
 500: #161E2E,
 600: #F9FAFB,
 700: #F9FAFB,
 800: #F9FAFB,
 900: #F9FAFB,
 )
),
 'cool-gray': (
  50: #FBFDFE,
 100: #F1F5F9,
 200: #E2E8F0,
 300: #CFD8E3,
 400: #97A6BA,
 500: #64748B,
 600: #475569,
 700: #364152,
 800: #27303F,
 900: #1A202E,
 contrast: (
  50: #1A202E,
 100: #1A202E,
 200: #1A202E,
 300: #1A202E,
 400: #1A202E,
 500: #1A202E,
 600: #FBFDFE,
 700: #FBFDFE,
 800: #FBFDFE,
 900: #FBFDFE,
 )
),
 'red': (
  50: #FDF2F2,
 100: #FDE8E8,
 200: #FBD5D5,
 300: #F8B4B4,
 400: #F98080,
 500: #F05252,
 600: #E02424,
 700: #C81E1E,
 800: #9B1C1C,
 900: #771D1D,
 contrast: (
  50: #771D1D,
 100: #771D1D,
 200: #771D1D,
 300: #771D1D,
 400: #771D1D,
 500: #771D1D,
 600: #FDF2F2,
 700: #FDF2F2,
 800: #FDF2F2,
 900: #FDF2F2,
 )
),
 'orange': (
  50: #FFF8F1,
 100: #FEECDC,
 200: #FCD9BD,
 300: #FDBA8C,
 400: #FF8A4C,
 500: #FF5A1F,
 600: #D03801,
 700: #B43403,
 800: #8A2C0D,
 900: #771D1D,
 contrast: (
  50: #771D1D,
 100: #771D1D,
 200: #771D1D,
 300: #771D1D,
 400: #771D1D,
 500: #771D1D,
 600: #FFF8F1,
 700: #FFF8F1,
 800: #FFF8F1,
 900: #FFF8F1,
 )
),
 'yellow': (
  50: #FDFDEA,
 100: #FDF6B2,
 200: #FCE96A,
 300: #FACA15,
 400: #E3A008,
 500: #C27803,
 600: #9F580A,
 700: #8E4B10,
 800: #723B13,
 900: #633112,
 contrast: (
  50: #633112,
 100: #633112,
 200: #633112,
 300: #633112,
 400: #633112,
 500: #633112,
 600: #FDFDEA,
 700: #FDFDEA,
 800: #FDFDEA,
 900: #FDFDEA,
 )
),
 'green': (
  50: #F3FAF7,
 100: #DEF7EC,
 200: #BCF0DA,
 300: #84E1BC,
 400: #31C48D,
 500: #0E9F6E,
 600: #057A55,
 700: #046C4E,
 800: #03543F,
 900: #014737,
 contrast: (
  50: #014737,
 100: #014737,
 200: #014737,
 300: #014737,
 400: #014737,
 500: #F3FAF7,
 600: #F3FAF7,
 700: #F3FAF7,
 800: #F3FAF7,
 900: #F3FAF7,
 )
),
 'teal': (
  50: #EDFAFA,
 100: #D5F5F6,
 200: #AFECEF,
 300: #7EDCE2,
 400: #16BDCA,
 500: #0694A2,
 600: #047481,
 700: #036672,
 800: #05505C,
 900: #014451,
 contrast: (
  50: #014451,
 100: #014451,
 200: #014451,
 300: #014451,
 400: #014451,
 500: #EDFAFA,
 600: #EDFAFA,
 700: #EDFAFA,
 800: #EDFAFA,
 900: #EDFAFA,
 )
),
 'blue': (
  50: #EBF5FF,
 100: #E1EFFE,
 200: #C3DDFD,
 300: #A4CAFE,
 400: #76A9FA,
 500: #3F83F8,
 600: #1C64F2,
 700: #1A56DB,
 800: #1E429F,
 900: #233876,
 contrast: (
  50: #233876,
 100: #233876,
 200: #233876,
 300: #233876,
 400: #233876,
 500: #EBF5FF,
 600: #EBF5FF,
 700: #EBF5FF,
 800: #EBF5FF,
 900: #EBF5FF,
 )
),
 'indigo': (
  50: #F0F5FF,
 100: #E5EDFF,
 200: #CDDBFE,
 300: #B4C6FC,
 400: #8DA2FB,
 500: #6875F5,
 600: #5850EC,
 700: #5145CD,
 800: #42389D,
 900: #362F78,
 contrast: (
  50: #362F78,
 100: #362F78,
 200: #362F78,
 300: #362F78,
 400: #362F78,
 500: #F0F5FF,
 600: #F0F5FF,
 700: #F0F5FF,
 800: #F0F5FF,
 900: #F0F5FF,
 )
),
 'purple': (
  50: #F6F5FF,
 100: #EDEBFE,
 200: #DCD7FE,
 300: #CABFFD,
 400: #AC94FA,
 500: #9061F9,
 600: #7E3AF2,
 700: #6C2BD9,
 800: #5521B5,
 900: #4A1D96,
 contrast: (
  50: #4A1D96,
 100: #4A1D96,
 200: #4A1D96,
 300: #4A1D96,
 400: #4A1D96,
 500: #F6F5FF,
 600: #F6F5FF,
 700: #F6F5FF,
 800: #F6F5FF,
 900: #F6F5FF,
 )
),
 'pink': (
  50: #FDF2F8,
 100: #FCE8F3,
 200: #FAD1E8,
 300: #F8B4D9,
 400: #F17EB8,
 500: #E74694,
 600: #D61F69,
 700: #BF125D,
 800: #99154B,
 900: #751A3D,
 contrast: (
  50: #751A3D,
 100: #751A3D,
 200: #751A3D,
 300: #751A3D,
 400: #751A3D,
 500: #FDF2F8,
 600: #FDF2F8,
 700: #FDF2F8,
 800: #FDF2F8,
 900: #FDF2F8,
 )
),
 ) !default;

$beagle-font-sans: Inter var,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;

$beagle-font-serif: Georgia,Cambria,"Times New Roman",Times,serif !default;

$beagle-font-mono: "IBM Plex Mono",Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !default;

$beagle-breakpoints: (
  xs: '(min-width: 0) and (max-width: 599px)',
 sm: '(min-width: 600px) and (max-width: 959px)',
 md: '(min-width: 960px) and (max-width: 1279px)',
 lg: '(min-width: 1280px) and (max-width: 1439px)',
 xl: '(min-width: 1440px)',
 lt-md: '(max-width: 959px)',
 lt-lg: '(max-width: 1279px)',
 lt-xl: '(max-width: 1439px)',
 gt-xs: '(min-width: 600px)',
 gt-sm: '(min-width: 960px)',
 gt-md: '(min-width: 1280px)',
 ) !default
