.btn-w-xs {
    width: 85px
}

.btn-w-sm {
    width: 100px
}

.btn-w-md {
    width: 120px
}

.btn-w-lg {
    width: 145px
}

.btn-w-xl {
    width: 180px
}

.b-0 {
    border: 0 solid #ebebeb!important
}

.bt-0 {
    border-top: 0 solid #ebebeb!important
}

.br-0 {
    border-right: 0 solid #ebebeb!important
}

.bb-0 {
    border-bottom: 0 solid #ebebeb!important
}

.bl-0 {
    border-left: 0 solid #ebebeb!important
}

.bx-0 {
    border-right: 0 solid #ebebeb!important;
    border-left: 0 solid #ebebeb!important
}

.by-0 {
    border-top: 0 solid #ebebeb!important;
    border-bottom: 0 solid #ebebeb!important
}

.b-1 {
    border: 1px solid #ebebeb!important
}

.bt-1 {
    border-top: 1px solid #ebebeb!important
}

.br-1 {
    border-right: 1px solid #ebebeb!important
}

.bb-1 {
    border-bottom: 1px solid #ebebeb!important
}

.bl-1 {
    border-left: 1px solid #ebebeb!important
}

.bx-1 {
    border-right: 1px solid #ebebeb!important;
    border-left: 1px solid #ebebeb!important
}

.by-1 {
    border-top: 1px solid #ebebeb!important;
    border-bottom: 1px solid #ebebeb!important
}

.b-2 {
    border: 2px solid #ebebeb!important
}

.bt-2 {
    border-top: 2px solid #ebebeb!important
}

.br-2 {
    border-right: 2px solid #ebebeb!important
}

.bb-2 {
    border-bottom: 2px solid #ebebeb!important
}

.bl-2 {
    border-left: 2px solid #ebebeb!important
}

.bx-2 {
    border-right: 2px solid #ebebeb!important;
    border-left: 2px solid #ebebeb!important
}

.by-2 {
    border-top: 2px solid #ebebeb!important;
    border-bottom: 2px solid #ebebeb!important
}

.b-3 {
    border: 3px solid #ebebeb!important
}

.bt-3 {
    border-top: 3px solid #ebebeb!important
}

.br-3 {
    border-right: 3px solid #ebebeb!important
}

.bb-3 {
    border-bottom: 3px solid #ebebeb!important
}

.bl-3 {
    border-left: 3px solid #ebebeb!important
}

.bx-3 {
    border-right: 3px solid #ebebeb!important;
    border-left: 3px solid #ebebeb!important
}

.by-3 {
    border-top: 3px solid #ebebeb!important;
    border-bottom: 3px solid #ebebeb!important
}

.w-0px {
    width: 0!important
}

.h-0px {
    height: 0!important
}

.w-10px {
    width: 10px!important
}

.h-10px {
    height: 10px!important
}

.w-20px {
    width: 20px!important
}

.h-20px {
    height: 20px!important
}

.w-25px {
    width: 25px!important
}

.h-25px {
    height: 25px!important
}

.w-30px {
    width: 30px!important
}

.h-30px {
    height: 30px!important
}

.w-40px {
    width: 40px!important
}

.h-40px {
    height: 40px!important
}

.w-50px {
    width: 50px!important
}

.h-50px {
    height: 50px!important
}

.w-60px {
    width: 60px!important
}

.h-60px {
    height: 60px!important
}

.w-64px {
    width: 64px!important
}

.h-64px {
    height: 64px!important
}

.w-70px {
    width: 70px!important
}

.h-70px {
    height: 70px!important
}

.w-75px {
    width: 75px!important
}

.h-75px {
    height: 75px!important
}

.w-80px {
    width: 80px!important
}

.h-80px {
    height: 80px!important
}

.w-90px {
    width: 90px!important
}

.h-90px {
    height: 90px!important
}

.w-100 {
    width: 100%!important
}

.w-100px {
    width: 100px!important
}

.h-100 {
    height: 100%!important
}

.h-100px {
    height: 100px!important
}

.w-120px {
    width: 120px!important
}

.h-120px {
    height: 120px!important
}

.w-140px {
    width: 140px!important
}

.h-140px {
    height: 140px!important
}

.w-150px {
    width: 150px!important
}

.h-150px {
    height: 150px!important
}

.w-160px {
    width: 160px!important
}

.h-160px {
    height: 160px!important
}

.w-180px {
    width: 180px!important
}

.h-180px {
    height: 180px!important
}

.w-200px {
    width: 200px!important
}

.h-200px {
    height: 200px!important
}

.w-250px {
    width: 250px!important
}

.h-250px {
    height: 250px!important
}

.w-300px {
    width: 300px!important
}

.h-300px {
    height: 300px!important
}

.w-350px {
    width: 350px!important
}

.h-350px {
    height: 350px!important
}

.w-400px {
    width: 400px!important
}

.h-400px {
    height: 400px!important
}

.w-450px {
    width: 450px!important
}

.h-450px {
    height: 450px!important
}

.w-500px {
    width: 500px!important
}

.h-500px {
    height: 500px!important
}

.w-600px {
    width: 600px!important
}

.h-600px {
    height: 600px!important
}

.w-700px {
    width: 700px!important
}

.h-700px {
    height: 700px!important
}

.w-800px {
    width: 800px!important
}

.h-800px {
    height: 800px!important
}

.w-900px {
    width: 900px!important
}

.h-900px {
    height: 900px!important
}

.w-0 {
    width: 0!important
}

.h-0 {
    height: 0!important
}

.w-full {
    width: 100%!important
}

.w-half {
    width: 50%!important
}

.w-third {
    width: 33.333333%!important
}

.w-fourth {
    width: 25%!important
}

.w-fifth {
    width: 20%!important
}

.h-full {
    height: 100%!important
}

.h-half {
    height: 50%!important
}

.h-third {
    height: 33.333333%!important
}

.h-fourth {
    height: 25%!important
}

.h-fifth {
    height: 20%!important
}

.w-fullscreen {
    width: 100vw!important
}

.h-fullscreen {
    height: 100vh!important
}

.min-w-fullscreen {
    min-width: 100vw!important
}

.min-h-fullscreen {
    min-height: 100vh!important
}

.m-0{
    margin: 0 !important;
}

.m-10 {
    margin: 10px!important
}

.mt-10 {
    margin-top: 10px!important
}

.mr-5 {
    margin-right: 5px!important
}

.mr-10 {
    margin-right: 10px!important
}

.mb-0{
    margin-bottom: 0!important;
}

.mb-10 {
    margin-bottom: 10px!important
}

.ml-10 {
    margin-left: 10px!important
}

.mx-0{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-10 {
    margin-right: 10px!important;
    margin-left: 10px!important
}

.my-0{
    margin-top: 0!important;
    margin-bottom: 0!important
}

.my-10 {
    margin-top: 10px!important;
    margin-bottom: 10px!important
}

.m-12 {
    margin: 12px!important
}

.mt-12 {
    margin-top: 12px!important
}

.mr-12 {
    margin-right: 12px!important
}

.mb-12 {
    margin-bottom: 12px!important
}

.ml-12 {
    margin-left: 12px!important
}

.mx-12 {
    margin-right: 12px!important;
    margin-left: 12px!important
}

.my-12 {
    margin-top: 12px!important;
    margin-bottom: 12px!important
}

.m-15 {
    margin: 15px!important
}

.mt-15 {
    margin-top: 15px!important
}

.mr-15 {
    margin-right: 15px!important
}

.mb-15 {
    margin-bottom: 15px!important
}

.ml-15 {
    margin-left: 15px!important
}

.mx-15 {
    margin-right: 15px!important;
    margin-left: 15px!important
}

.my-15 {
    margin-top: 15px!important;
    margin-bottom: 15px!important
}

.m-16 {
    margin: 16px!important
}

.mt-16 {
    margin-top: 16px!important
}

.mr-16 {
    margin-right: 16px!important
}

.mb-16 {
    margin-bottom: 16px!important
}

.ml-16 {
    margin-left: 16px!important
}

.mx-16 {
    margin-right: 16px!important;
    margin-left: 16px!important
}

.my-16 {
    margin-top: 16px!important;
    margin-bottom: 16px!important
}

.m-20 {
    margin: 20px!important
}

.mt-20 {
    margin-top: 20px!important
}

.mr-20 {
    margin-right: 20px!important
}

.mb-20 {
    margin-bottom: 20px!important
}

.ml-20 {
    margin-left: 20px!important
}

.mx-20 {
    margin-right: 20px!important;
    margin-left: 20px!important
}

.my-20 {
    margin-top: 20px!important;
    margin-bottom: 20px!important
}

.m-24 {
    margin: 24px!important
}

.mt-24 {
    margin-top: 24px!important
}

.mr-24 {
    margin-right: 24px!important
}

.mb-24 {
    margin-bottom: 24px!important
}

.ml-24 {
    margin-left: 24px!important
}

.mx-24 {
    margin-right: 24px!important;
    margin-left: 24px!important
}

.my-24 {
    margin-top: 24px!important;
    margin-bottom: 24px!important
}

.m-25 {
    margin: 25px!important
}

.mt-25 {
    margin-top: 25px!important
}

.mr-25 {
    margin-right: 25px!important
}

.mb-25 {
    margin-bottom: 25px!important
}

.ml-25 {
    margin-left: 25px!important
}

.mx-25 {
    margin-right: 25px!important;
    margin-left: 25px!important
}

.my-25 {
    margin-top: 25px!important;
    margin-bottom: 25px!important
}

.m-30 {
    margin: 30px!important
}

.mt-30 {
    margin-top: 30px!important
}

.mr-30 {
    margin-right: 30px!important
}

.mb-30 {
    margin-bottom: 30px!important
}

.ml-30 {
    margin-left: 30px!important
}

.mx-30 {
    margin-right: 30px!important;
    margin-left: 30px!important
}

.my-30 {
    margin-top: 30px!important;
    margin-bottom: 30px!important
}

.m-35 {
    margin: 35px!important
}

.mt-35 {
    margin-top: 35px!important
}

.mr-35 {
    margin-right: 35px!important
}

.mb-35 {
    margin-bottom: 35px!important
}

.ml-35 {
    margin-left: 35px!important
}

.mx-35 {
    margin-right: 35px!important;
    margin-left: 35px!important
}

.my-35 {
    margin-top: 35px!important;
    margin-bottom: 35px!important
}

.m-40 {
    margin: 40px!important
}

.mt-40 {
    margin-top: 40px!important
}

.mr-40 {
    margin-right: 40px!important
}

.mb-40 {
    margin-bottom: 40px!important
}

.ml-40 {
    margin-left: 40px!important
}

.mx-40 {
    margin-right: 40px!important;
    margin-left: 40px!important
}

.my-40 {
    margin-top: 40px!important;
    margin-bottom: 40px!important
}

.m-45 {
    margin: 45px!important
}

.mt-45 {
    margin-top: 45px!important
}

.mr-45 {
    margin-right: 45px!important
}

.mb-45 {
    margin-bottom: 45px!important
}

.ml-45 {
    margin-left: 45px!important
}

.mx-45 {
    margin-right: 45px!important;
    margin-left: 45px!important
}

.my-45 {
    margin-top: 45px!important;
    margin-bottom: 45px!important
}

.m-50 {
    margin: 50px!important
}

.mt-50 {
    margin-top: 50px!important
}

.mr-50 {
    margin-right: 50px!important
}

.mb-50 {
    margin-bottom: 50px!important
}

.ml-50 {
    margin-left: 50px!important
}

.mx-50 {
    margin-right: 50px!important;
    margin-left: 50px!important
}

.my-50 {
    margin-top: 50px!important;
    margin-bottom: 50px!important
}

.m-60 {
    margin: 60px!important
}

.mt-60 {
    margin-top: 60px!important
}

.mr-60 {
    margin-right: 60px!important
}

.mb-60 {
    margin-bottom: 60px!important
}

.ml-60 {
    margin-left: 60px!important
}

.mx-60 {
    margin-right: 60px!important;
    margin-left: 60px!important
}

.my-60 {
    margin-top: 60px!important;
    margin-bottom: 60px!important
}

.m-70 {
    margin: 70px!important
}

.mt-70 {
    margin-top: 70px!important
}

.mr-70 {
    margin-right: 70px!important
}

.mb-70 {
    margin-bottom: 70px!important
}

.ml-70 {
    margin-left: 70px!important
}

.mx-70 {
    margin-right: 70px!important;
    margin-left: 70px!important
}

.my-70 {
    margin-top: 70px!important;
    margin-bottom: 70px!important
}

.m-80 {
    margin: 80px!important
}

.mt-80 {
    margin-top: 80px!important
}

.mr-80 {
    margin-right: 80px!important
}

.mb-80 {
    margin-bottom: 80px!important
}

.ml-80 {
    margin-left: 80px!important
}

.mx-80 {
    margin-right: 80px!important;
    margin-left: 80px!important
}

.my-80 {
    margin-top: 80px!important;
    margin-bottom: 80px!important
}

.m-90 {
    margin: 90px!important
}

.mt-90 {
    margin-top: 90px!important
}

.mr-90 {
    margin-right: 90px!important
}

.mb-90 {
    margin-bottom: 90px!important
}

.ml-90 {
    margin-left: 90px!important
}

.mx-90 {
    margin-right: 90px!important;
    margin-left: 90px!important
}

.my-90 {
    margin-top: 90px!important;
    margin-bottom: 90px!important
}

.m-100 {
    margin: 100px!important
}

.mt-100 {
    margin-top: 100px!important
}

.mr-100 {
    margin-right: 100px!important
}

.mb-100 {
    margin-bottom: 100px!important
}

.ml-100 {
    margin-left: 100px!important
}

.mx-100 {
    margin-right: 100px!important;
    margin-left: 100px!important
}

.my-100 {
    margin-top: 100px!important;
    margin-bottom: 100px!important
}

.m-120 {
    margin: 120px!important
}

.mt-120 {
    margin-top: 120px!important
}

.mr-120 {
    margin-right: 120px!important
}

.mb-120 {
    margin-bottom: 120px!important
}

.ml-120 {
    margin-left: 120px!important
}

.mx-120 {
    margin-right: 120px!important;
    margin-left: 120px!important
}

.my-120 {
    margin-top: 120px!important;
    margin-bottom: 120px!important
}

.m-140 {
    margin: 140px!important
}

.mt-140 {
    margin-top: 140px!important
}

.mr-140 {
    margin-right: 140px!important
}

.mb-140 {
    margin-bottom: 140px!important
}

.ml-140 {
    margin-left: 140px!important
}

.mx-140 {
    margin-right: 140px!important;
    margin-left: 140px!important
}

.my-140 {
    margin-top: 140px!important;
    margin-bottom: 140px!important
}

.m-150 {
    margin: 150px!important
}

.mt-150 {
    margin-top: 150px!important
}

.mr-150 {
    margin-right: 150px!important
}

.mb-150 {
    margin-bottom: 150px!important
}

.ml-150 {
    margin-left: 150px!important
}

.mx-150 {
    margin-right: 150px!important;
    margin-left: 150px!important
}

.my-150 {
    margin-top: 150px!important;
    margin-bottom: 150px!important
}

.m-160 {
    margin: 160px!important
}

.mt-160 {
    margin-top: 160px!important
}

.mr-160 {
    margin-right: 160px!important
}

.mb-160 {
    margin-bottom: 160px!important
}

.ml-160 {
    margin-left: 160px!important
}

.mx-160 {
    margin-right: 160px!important;
    margin-left: 160px!important
}

.my-160 {
    margin-top: 160px!important;
    margin-bottom: 160px!important
}

.m-180 {
    margin: 180px!important
}

.mt-180 {
    margin-top: 180px!important
}

.mr-180 {
    margin-right: 180px!important
}

.mb-180 {
    margin-bottom: 180px!important
}

.ml-180 {
    margin-left: 180px!important
}

.mx-180 {
    margin-right: 180px!important;
    margin-left: 180px!important
}

.my-180 {
    margin-top: 180px!important;
    margin-bottom: 180px!important
}

.m-200 {
    margin: 200px!important
}

.mt-200 {
    margin-top: 200px!important
}

.mr-200 {
    margin-right: 200px!important
}

.mb-200 {
    margin-bottom: 200px!important
}

.ml-200 {
    margin-left: 200px!important
}

.mx-200 {
    margin-right: 200px!important;
    margin-left: 200px!important
}

.my-200 {
    margin-top: 200px!important;
    margin-bottom: 200px!important
}

.m-250 {
    margin: 250px!important
}

.mt-250 {
    margin-top: 250px!important
}

.mr-250 {
    margin-right: 250px!important
}

.mb-250 {
    margin-bottom: 250px!important
}

.ml-250 {
    margin-left: 250px!important
}

.mx-250 {
    margin-right: 250px!important;
    margin-left: 250px!important
}

.my-250 {
    margin-top: 250px!important;
    margin-bottom: 250px!important
}

.m-300 {
    margin: 300px!important
}

.mt-300 {
    margin-top: 300px!important
}

.mr-300 {
    margin-right: 300px!important
}

.mb-300 {
    margin-bottom: 300px!important
}

.ml-300 {
    margin-left: 300px!important
}

.mx-300 {
    margin-right: 300px!important;
    margin-left: 300px!important
}

.my-300 {
    margin-top: 300px!important;
    margin-bottom: 300px!important
}

.m-400 {
    margin: 400px!important
}

.mt-400 {
    margin-top: 400px!important
}

.mr-400 {
    margin-right: 400px!important
}

.mb-400 {
    margin-bottom: 400px!important
}

.ml-400 {
    margin-left: 400px!important
}

.mx-400 {
    margin-right: 400px!important;
    margin-left: 400px!important
}

.my-400 {
    margin-top: 400px!important;
    margin-bottom: 400px!important
}

.m-500 {
    margin: 500px!important
}

.mt-500 {
    margin-top: 500px!important
}

.mr-500 {
    margin-right: 500px!important
}

.mb-500 {
    margin-bottom: 500px!important
}

.ml-500 {
    margin-left: 500px!important
}

.mx-500 {
    margin-right: 500px!important;
    margin-left: 500px!important
}

.my-500 {
    margin-top: 500px!important;
    margin-bottom: 500px!important
}



.p-10 {
    padding: 10px!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-10 {
    padding-top: 10px!important
}

.pr-10 {
    padding-right: 10px!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-10 {
    padding-bottom: 10px!important
}

.pl-0{
    padding-left: 0!important;
}

.pl-10 {
    padding-left: 10px!important
}

.px-10 {
    padding-right: 10px!important;
    padding-left: 10px!important
}

.py-10 {
    padding-top: 10px!important;
    padding-bottom: 10px!important
}

.p-12 {
    padding: 12px!important
}

.pt-12 {
    padding-top: 12px!important
}

.pr-12 {
    padding-right: 12px!important
}

.pb-12 {
    padding-bottom: 12px!important
}

.pl-12 {
    padding-left: 12px!important
}

.px-12 {
    padding-right: 12px!important;
    padding-left: 12px!important
}

.py-12 {
    padding-top: 12px!important;
    padding-bottom: 12px!important
}

.p-15 {
    padding: 15px!important
}

.pt-15 {
    padding-top: 15px!important
}

.pr-15 {
    padding-right: 15px!important
}

.pb-15 {
    padding-bottom: 15px!important
}

.pl-15 {
    padding-left: 15px!important
}

.px-15 {
    padding-right: 15px!important;
    padding-left: 15px!important
}

.py-15 {
    padding-top: 15px!important;
    padding-bottom: 15px!important
}

.p-16 {
    padding: 16px!important
}

.pt-16 {
    padding-top: 16px!important
}

.pr-16 {
    padding-right: 16px!important
}

.pb-16 {
    padding-bottom: 16px!important
}

.pl-16 {
    padding-left: 16px!important
}

.px-16 {
    padding-right: 16px!important;
    padding-left: 16px!important
}

.py-16 {
    padding-top: 16px!important;
    padding-bottom: 16px!important
}

.p-20 {
    padding: 20px!important
}

.pt-20 {
    padding-top: 20px!important
}

.pr-20 {
    padding-right: 20px!important
}

.pb-20 {
    padding-bottom: 20px!important
}

.pl-20 {
    padding-left: 20px!important
}

.px-20 {
    padding-right: 20px!important;
    padding-left: 20px!important
}

.py-20 {
    padding-top: 20px!important;
    padding-bottom: 20px!important
}

.p-24 {
    padding: 24px!important
}

.pt-24 {
    padding-top: 24px!important
}

.pr-24 {
    padding-right: 24px!important
}

.pb-24 {
    padding-bottom: 24px!important
}

.pl-24 {
    padding-left: 24px!important
}

.px-24 {
    padding-right: 24px!important;
    padding-left: 24px!important
}

.py-24 {
    padding-top: 24px!important;
    padding-bottom: 24px!important
}

.p-25 {
    padding: 25px!important
}

.pt-25 {
    padding-top: 25px!important
}

.pr-25 {
    padding-right: 25px!important
}

.pb-25 {
    padding-bottom: 25px!important
}

.pl-25 {
    padding-left: 25px!important
}

.px-25 {
    padding-right: 25px!important;
    padding-left: 25px!important
}

.py-25 {
    padding-top: 25px!important;
    padding-bottom: 25px!important
}

.p-30 {
    padding: 30px!important
}

.pt-30 {
    padding-top: 30px!important
}

.pr-30 {
    padding-right: 30px!important
}

.pb-30 {
    padding-bottom: 30px!important
}

.pl-30 {
    padding-left: 30px!important
}

.px-30 {
    padding-right: 30px!important;
    padding-left: 30px!important
}

.py-30 {
    padding-top: 30px!important;
    padding-bottom: 30px!important
}

.p-35 {
    padding: 35px!important
}

.pt-35 {
    padding-top: 35px!important
}

.pr-35 {
    padding-right: 35px!important
}

.pb-35 {
    padding-bottom: 35px!important
}

.pl-35 {
    padding-left: 35px!important
}

.px-35 {
    padding-right: 35px!important;
    padding-left: 35px!important
}

.py-35 {
    padding-top: 35px!important;
    padding-bottom: 35px!important
}

.p-40 {
    padding: 40px!important
}

.pt-40 {
    padding-top: 40px!important
}

.pr-40 {
    padding-right: 40px!important
}

.pb-40 {
    padding-bottom: 40px!important
}

.pl-40 {
    padding-left: 40px!important
}

.px-40 {
    padding-right: 40px!important;
    padding-left: 40px!important
}

.py-40 {
    padding-top: 40px!important;
    padding-bottom: 40px!important
}

.p-45 {
    padding: 45px!important
}

.pt-45 {
    padding-top: 45px!important
}

.pr-45 {
    padding-right: 45px!important
}

.pb-45 {
    padding-bottom: 45px!important
}

.pl-45 {
    padding-left: 45px!important
}

.px-45 {
    padding-right: 45px!important;
    padding-left: 45px!important
}

.py-45 {
    padding-top: 45px!important;
    padding-bottom: 45px!important
}

.p-50 {
    padding: 50px!important
}

.pt-50 {
    padding-top: 50px!important
}

.pr-50 {
    padding-right: 50px!important
}

.pb-50 {
    padding-bottom: 50px!important
}

.pl-50 {
    padding-left: 50px!important
}

.px-50 {
    padding-right: 50px!important;
    padding-left: 50px!important
}

.py-50 {
    padding-top: 50px!important;
    padding-bottom: 50px!important
}

.p-60 {
    padding: 60px!important
}

.pt-60 {
    padding-top: 60px!important
}

.pr-60 {
    padding-right: 60px!important
}

.pb-60 {
    padding-bottom: 60px!important
}

.pl-60 {
    padding-left: 60px!important
}

.px-60 {
    padding-right: 60px!important;
    padding-left: 60px!important
}

.py-60 {
    padding-top: 60px!important;
    padding-bottom: 60px!important
}

.p-70 {
    padding: 70px!important
}

.pt-70 {
    padding-top: 70px!important
}

.pr-70 {
    padding-right: 70px!important
}

.pb-70 {
    padding-bottom: 70px!important
}

.pl-70 {
    padding-left: 70px!important
}

.px-70 {
    padding-right: 70px!important;
    padding-left: 70px!important
}

.py-70 {
    padding-top: 70px!important;
    padding-bottom: 70px!important
}

.p-80 {
    padding: 80px!important
}

.pt-80 {
    padding-top: 80px!important
}

.pr-80 {
    padding-right: 80px!important
}

.pb-80 {
    padding-bottom: 80px!important
}

.pl-80 {
    padding-left: 80px!important
}

.px-80 {
    padding-right: 80px!important;
    padding-left: 80px!important
}

.py-80 {
    padding-top: 80px!important;
    padding-bottom: 80px!important
}

.p-90 {
    padding: 90px!important
}

.pt-90 {
    padding-top: 90px!important
}

.pr-90 {
    padding-right: 90px!important
}

.pb-90 {
    padding-bottom: 90px!important
}

.pl-90 {
    padding-left: 90px!important
}

.px-90 {
    padding-right: 90px!important;
    padding-left: 90px!important
}

.py-90 {
    padding-top: 90px!important;
    padding-bottom: 90px!important
}

.p-100 {
    padding: 100px!important
}

.pt-100 {
    padding-top: 100px!important
}

.pr-100 {
    padding-right: 100px!important
}

.pb-100 {
    padding-bottom: 100px!important
}

.pl-100 {
    padding-left: 100px!important
}

.px-100 {
    padding-right: 100px!important;
    padding-left: 100px!important
}

.py-100 {
    padding-top: 100px!important;
    padding-bottom: 100px!important
}

.p-120 {
    padding: 120px!important
}

.pt-120 {
    padding-top: 120px!important
}

.pr-120 {
    padding-right: 120px!important
}

.pb-120 {
    padding-bottom: 120px!important
}

.pl-120 {
    padding-left: 120px!important
}

.px-120 {
    padding-right: 120px!important;
    padding-left: 120px!important
}

.py-120 {
    padding-top: 120px!important;
    padding-bottom: 120px!important
}

.p-140 {
    padding: 140px!important
}

.pt-140 {
    padding-top: 140px!important
}

.pr-140 {
    padding-right: 140px!important
}

.pb-140 {
    padding-bottom: 140px!important
}

.pl-140 {
    padding-left: 140px!important
}

.px-140 {
    padding-right: 140px!important;
    padding-left: 140px!important
}

.py-140 {
    padding-top: 140px!important;
    padding-bottom: 140px!important
}

.p-150 {
    padding: 150px!important
}

.pt-150 {
    padding-top: 150px!important
}

.pr-150 {
    padding-right: 150px!important
}

.pb-150 {
    padding-bottom: 150px!important
}

.pl-150 {
    padding-left: 150px!important
}

.px-150 {
    padding-right: 150px!important;
    padding-left: 150px!important
}

.py-150 {
    padding-top: 150px!important;
    padding-bottom: 150px!important
}

.p-160 {
    padding: 160px!important
}

.pt-160 {
    padding-top: 160px!important
}

.pr-160 {
    padding-right: 160px!important
}

.pb-160 {
    padding-bottom: 160px!important
}

.pl-160 {
    padding-left: 160px!important
}

.px-160 {
    padding-right: 160px!important;
    padding-left: 160px!important
}

.py-160 {
    padding-top: 160px!important;
    padding-bottom: 160px!important
}

.p-180 {
    padding: 180px!important
}

.pt-180 {
    padding-top: 180px!important
}

.pr-180 {
    padding-right: 180px!important
}

.pb-180 {
    padding-bottom: 180px!important
}

.pl-180 {
    padding-left: 180px!important
}

.px-180 {
    padding-right: 180px!important;
    padding-left: 180px!important
}

.py-180 {
    padding-top: 180px!important;
    padding-bottom: 180px!important
}

.p-200 {
    padding: 200px!important
}

.pt-200 {
    padding-top: 200px!important
}

.pr-200 {
    padding-right: 200px!important
}

.pb-200 {
    padding-bottom: 200px!important
}

.pl-200 {
    padding-left: 200px!important
}

.px-200 {
    padding-right: 200px!important;
    padding-left: 200px!important
}

.py-200 {
    padding-top: 200px!important;
    padding-bottom: 200px!important
}

.p-250 {
    padding: 250px!important
}

.pt-250 {
    padding-top: 250px!important
}

.pr-250 {
    padding-right: 250px!important
}

.pb-250 {
    padding-bottom: 250px!important
}

.pl-250 {
    padding-left: 250px!important
}

.px-250 {
    padding-right: 250px!important;
    padding-left: 250px!important
}

.py-250 {
    padding-top: 250px!important;
    padding-bottom: 250px!important
}

.p-300 {
    padding: 300px!important
}

.pt-300 {
    padding-top: 300px!important
}

.pr-300 {
    padding-right: 300px!important
}

.pb-300 {
    padding-bottom: 300px!important
}

.pl-300 {
    padding-left: 300px!important
}

.px-300 {
    padding-right: 300px!important;
    padding-left: 300px!important
}

.py-300 {
    padding-top: 300px!important;
    padding-bottom: 300px!important
}

.p-400 {
    padding: 400px!important
}

.pt-400 {
    padding-top: 400px!important
}

.pr-400 {
    padding-right: 400px!important
}

.pb-400 {
    padding-bottom: 400px!important
}

.pl-400 {
    padding-left: 400px!important
}

.px-400 {
    padding-right: 400px!important;
    padding-left: 400px!important
}

.py-400 {
    padding-top: 400px!important;
    padding-bottom: 400px!important
}

.p-500 {
    padding: 500px!important
}

.pt-500 {
    padding-top: 500px!important
}

.pr-500 {
    padding-right: 500px!important
}

.pb-500 {
    padding-bottom: 500px!important
}

.pl-500 {
    padding-left: 500px!important
}

.px-500 {
    padding-right: 500px!important;
    padding-left: 500px!important
}

.py-500 {
    padding-top: 500px!important;
    padding-bottom: 500px!important
}

.fs-8 {
    font-size: 8px!important
}

.fs-9 {
    font-size: 9px!important
}

.fs-10 {
    font-size: 10px!important
}

.fs-11 {
    font-size: 11px!important
}

.fs-12 {
    font-size: 12px!important
}

.fs-13 {
    font-size: 13px!important
}

.fs-14 {
    font-size: 14px!important
}

.fs-15 {
    font-size: 15px!important
}

.fs-16 {
    font-size: 16px!important
}

.fs-17 {
    font-size: 17px!important
}

.fs-18 {
    font-size: 18px!important
}

.fs-19 {
    font-size: 19px!important
}

.fs-20 {
    font-size: 20px!important
}

.fs-22 {
    font-size: 22px!important
}

.fs-24 {
    font-size: 24px!important
}

.fs-25 {
    font-size: 25px!important
}

.fs-26 {
    font-size: 26px!important
}

.fs-28 {
    font-size: 28px!important
}

.fs-30 {
    font-size: 30px!important;
    line-height: 1.2
}

.fs-35 {
    font-size: 35px!important;
    line-height: 1.2
}

.fs-40 {
    font-size: 40px!important;
    line-height: 1.2
}

.fs-45 {
    font-size: 45px!important;
    line-height: 1.2
}

.fs-50 {
    font-size: 50px!important;
    line-height: 1.2
}

.fs-60 {
    font-size: 60px!important;
    line-height: 1.2
}

.fs-70 {
    font-size: 70px!important;
    line-height: 1.2
}

.fs-80 {
    font-size: 80px!important;
    line-height: 1.2
}

.fs-90 {
    font-size: 90px!important;
    line-height: 1.2
}

.fw-100 {
    font-weight: 100!important
}

.fw-200 {
    font-weight: 200!important
}

.fw-300 {
    font-weight: 300!important
}

.fw-400 {
    font-weight: 400!important
}

.fw-500 {
    font-weight: 500!important
}

.fw-600 {
    font-weight: 600!important
}

.fw-700 {
    font-weight: 700!important
}

.fw-800 {
    font-weight: 800!important
}

.fw-900 {
    font-weight: 900!important
}

.lh-0 {
    line-height: 0!important
}

.lh-1 {
    line-height: 1!important
}

.lh-11 {
    line-height: 1.1!important
}

.lh-12 {
    line-height: 1.2!important
}

.lh-13 {
    line-height: 1.3!important
}

.lh-14 {
    line-height: 1.4!important
}

.lh-15 {
    line-height: 1.5!important
}

.lh-16 {
    line-height: 1.6!important
}

.lh-17 {
    line-height: 1.7!important
}

.lh-18 {
    line-height: 1.8!important
}

.lh-19 {
    line-height: 1.9!important
}

.lh-2 {
    line-height: 2!important
}

.lh-22 {
    line-height: 2.2!important
}

.lh-24 {
    line-height: 2.4!important
}

.lh-25 {
    line-height: 2.5!important
}

.lh-26 {
    line-height: 2.6!important
}

.lh-28 {
    line-height: 2.8!important
}

.lh-3 {
    line-height: 3!important
}

.lh-35 {
    line-height: 3.5!important
}

.lh-4 {
    line-height: 4!important
}

.lh-45 {
    line-height: 4.5!important
}

.lh-5 {
    line-height: 5!important
}

.letter-spacing-0 {
    letter-spacing: 0!important
}

.ls-0 {
    letter-spacing: 0!important
}

.letter-spacing-1 {
    letter-spacing: 1px!important
}

.ls-1 {
    letter-spacing: 1px!important
}

.letter-spacing-2 {
    letter-spacing: 2px!important
}

.ls-2 {
    letter-spacing: 2px!important
}

.letter-spacing-3 {
    letter-spacing: 3px!important
}

.ls-3 {
    letter-spacing: 3px!important
}

.letter-spacing-4 {
    letter-spacing: 4px!important
}

.ls-4 {
    letter-spacing: 4px!important
}

.letter-spacing-5 {
    letter-spacing: 5px!important
}

.ls-5 {
    letter-spacing: 5px!important
}

.clear-both {
    clear: both
}

.b-radius-0 {
    border-radius: 0!important
}

.b-radius-5 {
    border-radius: 5px!important
}

.b-radius-10 {
    border-radius: 10px!important
}

.b-radius-15 {
    border-radius: 15px!important
}

.b-radius-20 {
    border-radius: 20px!important
}

.b-radius-25 {
    border-radius: 25px!important
}

.float-left {
    float: left
}

.float-right {
    float: right
}

.box-shadow {
    box-shadow: 0 0 20px rgba(38, 38, 38, .2)
}

.line-height-10 {
    line-height: 10px
}

.line-height-20 {
    line-height: 20px
}

.line-height-30 {
    line-height: 30px
}

.line-height-40 {
    line-height: 40px
}

.line-height-50 {
    line-height: 50px
}

.right-10 {
    right: 10px!important
}

.right-20 {
    right: 20px!important
}

.right-30 {
    right: 30px!important
}
