// -----------------------------------------------------------------------------------------------------
// @ You can use this file to import custom styles.
//
// @ Styles from this file will override anything from 'vendors.scss' file allowing customizations and
//  modifications of third party libraries.
// -----------------------------------------------------------------------------------------------------
@import "beagle";

a {
    &:hover {
        color: unset;
    }
}

@include beagle-breakpoint('xs') {
    .d-xs-none {
        display: none !important;
    }

    .container {
        padding: 0 !important;
    }
}

.mat-menu-content {
    min-width: 200px;
}

.mat-menu-item {
    padding: 0 36px !important;
    font-size: 13px;
    font-weight: 600;
}

.mat-form-field.mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
        margin-bottom: 0 !important;
    }
}

.mat-form-field-flex {
    background-color: #ededed !important;
    border-color: #ededed !important;
}

.beagle-theme-light {
    color: #27303f;
    background-color: #EDEDED;
}

.text-muted {
    color: #9e9e9e;
}

.text-link {
    font-weight: 600;
    //color: #8a122a !important;
}

.text-italy {
    font-style: italic;
}

.text-success {
    color: #17b56c !important;
}

.btn-primary-outline {
    background-color: transparent !important;
    color: #8a122a;
    border: 1px solid #8a122a;
    font-weight: 600;
    min-height: 50px;
    height: 50px;
}

.btn-primary {
    font-weight: 600;
    min-height: 50px;
    height: 50px;

    &.btn-default {
        background: #8a122a !important;
        color: #FFFFFF !important;
    }
}

.title-mobile {
    padding: 1rem;
    display: flex;

    .mat-icon {
        color: #FFFFFF;
    }

    .title {
        margin-left: 1rem;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 1rem;
    }
}

.btn-md {
    font-size: 17px;
    font-weight: 300;
    padding: 16px 30px 15px !important;
}

.btn-block {
    min-width: 100% !important;
}

.btn {
    -webkit-tap-highlight-color: transparent;
    appearance: none;
    border: 0;
    min-width: 140px;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 32px;
    display: inline-block;
    width: auto;
    line-height: 1;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    outline: 0;
    text-align: center;
    transition: background .1s ease-in,color .1s ease-in,border .1s ease-in;

    &.btn-primary {
        background: #1852cc;
        color: #FFFFFF;
    }

    &.btn-secondary {
        background: rgb(24 82 204 / 18%);
        color: #1852cc;
    }

    &:disabled {
        background: #bdc2ce !important;
    }

    &.btn-basic-primary {
        min-width: 60px !important;
        padding: 16px 22px !important;
        color: #1852cc;
    }
}

.icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    position: relative;
    top: 2px;
}

.icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    position: relative;
    top: 2px;
}

.btn,
.form-control {
    &:focus {
        box-shadow: none !important;
    }
}

header {
    color: #353535;
    padding: 1.2rem 1rem;
   
    .top {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        
    }

    .title {
        margin-top: .8rem;
        font-size: 1.5rem;
        font-weight: 100;
        padding-left: .2rem;
        max-width: 970px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .icon-button {
        position: relative;
        top: 2px;

        .mat-icon {
            min-height: 10px;
            height: 30px;
            color: #353535;
        }
    }
}

.menu {
    box-shadow: -5px -5px 13px -3px rgba(184,184,184,0.75);
    -webkit-box-shadow: 1px -5px 13px -3px rgba(184,184,184,0.75);
    -moz-box-shadow: 1px -5px 13px -3px rgba(184,184,184,0.75);
    position: absolute;
    background-color: #FFFFFF;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: space-evenly;

        li {
            display: inline;

            a {
                display: inline-block;
                text-decoration: none;
                position: relative;
                top: 50%;
                transform: translateY(-70%);

                &.center {
                    position: relative;
                    top: 55% !important;
                    height: 65px;
                    width: 65px;
                    border-radius: 50px;
                    text-align: center;
                    border: 4px solid #FFF;

                    .mat-icon {
                        height: 25px;
                        width: 25px;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                .mat-icon {
                    height: 20px;
                    width: 20px;
                    min-height: 20px;
                }
            }
        }
    }
}
