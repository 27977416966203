// -----------------------------------------------------------------------------------------------------
// @ You can use this file to import styles from third party libraries.
//
// @ It's important to put them here because anything imported from this file can be overridden by
//   Beagle which allows having out-of-the-box support for certain libraries. They can also be
//   overridden from 'styles.scss' file which allows you to override and make any third party library
//   that Beagle doesn't support out-of-the-box visually compatible with your application.
// -----------------------------------------------------------------------------------------------------

// Perfect scrollbar
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Utils
@import "./../assets/css/utils.css";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
